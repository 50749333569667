import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Grid } from "@material-ui/core";
import "./Storefront.scss";
import bottles from "../../../images/line.jpg";
import catalog from "../../../images/catalog-2.png";
import ms from "../../../images/get_from_ms.png";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router";

export default function Storefront() {
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <div className={"window"}>
      <div className={"container"}>
        <LazyLoad offset={200} once>
          <Grid container spacing={4}>
            <Grid item sm={12} md={7}>
              <h1>
                <Trans i18nKey={"storefront.window.title"}>
                  The new <span className={"filled"}>premium</span>
                  <br /> lubricant.
                </Trans>
              </h1>
              <p>{t("storefront.window.description")}</p>

              <div className={"catalog"}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4} md={4}>
                    <img
                      src={catalog}
                      className={"catalogImage"}
                      alt={"German Gold Catalog"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <div className={"title"}>
                      {t("storefront.catalog.title")}
                    </div>
                    <a
                      href="https://www.flipsnack.com/germangold/gg-katalog-2024-25-web/download-pdf.html"
                      target="_BLANK"
                      rel="noopener noreferrer"
                      download="Catalog.pdf"
                    >
                      <i
                        className={"far fa-chevron-right"}
                        style={{ marginRight: 5, fontSize: 14 }}
                      ></i>{" "}
                      {t("storefront.catalog.pdf")}
                    </a>
                     
                     <a
                      href=" https://s3.eu-west-1.amazonaws.com/cdn.german-gold.com/catalog/GG-Katalog-Aug-2024-France-WEB.pdf"
                      target="_BLANK"
                      rel="noopener noreferrer"
                      download="Catalog.pdf"
                    >
                      <i
                        className={"far fa-chevron-right"}
                        style={{ marginRight: 5, fontSize: 14 }}
                      ></i>{" "}
                      Télécharger le PDF en français
                    </a>
                    <a
                      href="https://www.german-gold.de/media-center/catalog/"
                      target="_BLANK"
                      rel="noopener noreferrer"
                    >
                      <i
                        className={"far fa-chevron-right"}
                        style={{ marginRight: 5, fontSize: 14 }}
                      ></i>{" "}
                      {t("storefront.catalog.online")}
                    </a>

                    <div style={{ marginTop: "35px" }}>
                      <div className={"title"}>
                        {t("storefront.matrix.title")}
                      </div>

                      <span
                        className={"poseAsUrl"}
                        onClick={() => {
                          history.push("/matrix");
                        }}
                      >
                        <i
                          className={"far fa-chevron-right"}
                          style={{ marginRight: 5, fontSize: 14 }}
                        ></i>{" "}
                        {t("storefront.matrix.link")}
                      </span>
                    </div>

                    <div style={{ marginTop: "35px" }} className={"appWrapper"}>
                      <div className={"title"}>{t("storefront.app.title")}</div>
                      <p style={{ color: "#fff", fontSize: "17px" }}>
                        {t("storefront.app.text")}
                      </p>

           
                      <div className={"appIcons"}>
                        <a href="https://play.google.com/store/apps/details?id=gg.oilfinder.twa&utm_source=oilfinder&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                          <img
                            alt="Get it on Google Play"
                            style={{
                              width: "155px",
                              marginLeft: "-7px",
                              float: "left",
                            }}
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                          />
                        </a>
                        <a href="https://apps.apple.com/us/app/oilfinder/id1580518827?itsct=apps_box_link&itscg=30200">
                          <img
                            alt="Download on the App Store"
                            style={{
                              float: "left",
                              marginTop: 10,
                            }}
                            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                          />
                        </a>
                        <a href="//www.microsoft.com/store/apps/9PM07MS2RB2R?cid=storebadge&ocid=badge">
                          <img
                            src={ms}
                            alt="Get it on the Microsoft Store"
                            style={{
                              width: "120px",
                              marginTop: "7px",
                              marginLeft: "7px",
                              float: "left",
                            }}
                          />
                        </a>
                        <div
                          className={"clear"}
                          style={{ clear: "both" }}
                        ></div>
                      </div>

                      <p style={{ fontSize: 9, color: "#4c4c4c" }}>
                        {t("storefront.app.trademark")}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item sm={12} md={5}>
              <LazyLoad height={200} offsetTop={500}>
                <img
                  src={bottles}
                  className={"brandImage"}
                  alt={"German Gold Brand"}
                />
              </LazyLoad>
            </Grid>
          </Grid>
        </LazyLoad>
      </div>
    </div>
  );
}
