import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./header.scss";
import logo from "../../images/branding/logo-oilfinder.svg";
import Menu from "./Menu/Menu";
import {isIE} from "react-device-detect";
import CookieMessage from "./CookieMessage";

export default function Header() {
  const { t } = useTranslation();

  if(isIE){
    return (
      <div id="toptoast">
        <p>{t("aged")}</p>
      </div>
    )
  }

  return (
    <header>
      <div className={"main"}>
        <div className={"container"}>
          <div className={"branding"}>
            <img src={logo} alt={"German Gold Brand"} />
          </div>
          <Menu />
        </div>
      </div>
    </header>
  );
}
