import React from "react";
import { Link, matchPath, useHistory } from "react-router-dom";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";

export default function Menu() {
  const history = useHistory();
  const { t } = useTranslation();

  const menuItems = [
    {
      name: t("menu.products"),
      to: "/",
      match: "/",
    },
  ];

  return (
    <div>
      <div className={"sticker"}>
        <LanguageSwitcher />
      </div>
      <div className={"menu"} style={{ zIndex: 5 }}>
        <ul>
          {menuItems.map((obj, key) => {
            const path = `${obj.to}`;
            const match = `${obj.match}`;
            const isTrue = matchPath(history.location.pathname, {
              path: match,
              exact: false,
              strict: false,
            });

            return (
              <Link
                to={path}
                key={obj.name}
                className={isTrue ? "active" : "idle"}
              >
                <li>{obj.name}</li>
              </Link>
            );
          })}
          <li>
            <a href="https://german-gold.de">{t("menu.back")}</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
