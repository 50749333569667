import React from "react";
import "./overview.scss";
import Searchbar from "../../../components/Header/Searchbar";
import Catalog from "../../../components/Categories/Catalog";
import Storefront from "../../../components/Blocks/Storefront/Storefront";
import PersonalStore from "../../../components/Store/PersonalStore";

export default function Overview() {
  return (
    <div id={"catalog"}>
      <div id={"visualize"}>
      <div className={"container"}>
        <Searchbar />

        <div className={"flipbook"}>
            <iframe
              src="https://player.flipsnack.com?hash=N0VBNkY5Q0M1QTgreGs4YjVhOGNqbA=="
              width="100%"
              height="480"
              seamless="seamless"
              scrolling="no"
              frameBorder="0"
              allowFullScreen
              title="German Gold Catalog"
            ></iframe>
        </div>

        <div className={"categories"}>
          <Catalog />
        </div>
      </div>
      </div>
    
      <div className={"storefront"}>
        <div className={"container"}>
          <Storefront />
        </div>
      </div>
    </div>
  );
}
