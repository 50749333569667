import React, { useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import Header from "../components/Header/Header";
import Overview from "./Frontdesk/Overview/Overview";
import Footer from "../components/Footer/Footer";
import Category from "./Frontdesk/Category/Category";
import { useDispatch } from "react-redux";
import api from "../utils/api";
import { setCategories } from "../store/actions/categoriesActions";
import Product from "./Frontdesk/Product/Product";
import SimpleReactLightbox from "simple-react-lightbox";
import { SnackbarProvider } from "notistack";
import Matrix from "./Frontdesk/Matrix/Matrix";
import FloatingButton from "../components/Blocks/Fab/FloatingButton";

function App() {
  const dispatch = useDispatch();
  
  const [storeLoaded, setStoreLoaded] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);

  if (!storeLoaded && !storeLoading) {
    setStoreLoading(true);

    api(
      `GetCategories`,
      {},
      {},
      {
        "x-api-authorization": "GGAV-APIX-J0HG-UA90-02QQ",
      }
    )
      .then(result => {
        if (result.data.errorCode !== "200") {
          setStoreLoading(false);
          setStoreLoaded(true);

          return;
        }

        dispatch(setCategories(result.data.result.values));
        setStoreLoaded(true);
        setStoreLoading(false);
      })
      .catch(err => {
        console.error(err);
      });
  }

  return (
    <SimpleReactLightbox>
      <SnackbarProvider maxSnack={4}>
        <BrowserRouter>
          <div className={"finder"}>
            <div className={"data"}>
              <Header />
            </div>
            <div>
              <Switch>
                <Route path={"/products/overview"} exact>
                  <Redirect to={"/"} />
                </Route>

                <Route path={"/"} component={Overview} exact/>
                <Route path={"/matrix"} component={Matrix} exact/>

                <Route
                  path={"/products/category/:category"}
                  component={Category}
                />
                <Route
                  path={"/products/category/:category/:readable"}
                  component={Category}
                />

                <Route path={"/products/:product"} component={Product} />
                <Route
                  path={"/products/:product/:readable"}
                  component={Product}
                />
              </Switch>
            </div>
            <FloatingButton />
          </div>

          <Footer />
        </BrowserRouter>
      </SnackbarProvider>
    </SimpleReactLightbox>
  );
}

export default App;
