import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#fcbe07",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: "none !important",
      },
    },
  },
});
