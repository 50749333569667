import React, { useState, useEffect } from "react";
import "./category.scss";
import { connect } from "react-redux";
import NotFound from "../../../components/Errors/NotFound/NotFound";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Catalog from "../../../components/Categories/Catalog";
import Items from "../../../components/Category/Items/Items";

function Category({ categories, match }) {
  const { t } = useTranslation();
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState([]);

  let gridStyle = {};

  useEffect(() => {
    setCategory(
      categories.filter(value => value.short === match.params.category)
    );
  }, [categories, match.params.category]);

  if (category === null || categories.length === 0) {
    return null;
  }

  if (category && category.length < 1) {
    return <NotFound />;
  }

  if (category[0] !== undefined) {   
    gridStyle = {
      backgroundImage: "url(" + category[0].path + ")",
    };
  }

  return (
    <div id={"categoryPage"}>
      {category[0] !== undefined && (
        <div>
          <div id={"categoryEngine"}>
            <div className={"container"}>
              <div className={"categoryData"}>
                <div className={"categoryHeader"}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                      <div className={"categoryImage"} style={gridStyle} />
                    </Grid>
                    <Grid item sm={12} md={8}>
                      <div className={"pageHeader"}>
                        <h1 className={"title"}>{category[0].name}</h1>
                        <h3 style={{ marginBottom: 0 }}>
                          {category[0].description ? category[0].description : t("category.description")}
                        </h3>
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    className={"backButton"}
                    style={{ marginBottom: 7 }}
                    onClick={() => {
                      history.push(`/`);
                    }}
                  >
                    <span style={{ color: "#e7cb75", paddingRight: 9 }}>
                      <i className={"fas fa-caret-right"}></i>
                    </span>
                    <span>Go back to the search</span>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
                <Items identifier={category[0].id} />
              </div>
            </div>
          </div>
          <div className={"more"}>
            <div className={"space"}>
              <Catalog small />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default connect(state => ({
  categories: state.categories,
}))(Category);
