import React, { useState } from "react";
import { connect } from "react-redux";
import "./Articles.scss";
import {
  Paper,
  TableContainer,
  withStyles,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Toolbar,
  Typography,
} from "@material-ui/core";

const ArticleCell = withStyles(theme => ({
  head: {
    backgroundColor: "#111111",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "16px",
  },
  body: {
    backgroundColor: "#a09f9f",
    fontSize: "16px",
  },
}))(TableCell);

function Articles({ product, loading = false }) {
  const [articles, setArticles] = useState(product.articles);
  const [recommendations, setRecommendations] = useState(
    product.recommendations
  );
  const [specifications, setSpecifications] = useState(product.specifications);

  return (
    <div className={"articleWrapper"}>
      <Paper style={{ overflow: "hidden" }}>
      <Toolbar className={"bar"}>
          <Typography className={"title"}>Items</Typography>
        </Toolbar>
        <TableContainer>
          <Table className={"articleTable"} style={{ overflow: "hidden" }}>
            <TableHead>
              <TableRow key={"title"}>
                <ArticleCell>Art. No.</ArticleCell>
                <ArticleCell>Packing Unit</ArticleCell>
                <ArticleCell>Specification</ArticleCell>
                <ArticleCell>Recommended Use</ArticleCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articles.map((item, key) => {
                return (
                  <TableRow key={key}>
                    <ArticleCell>{item.artikel_nummer}</ArticleCell>
                    <ArticleCell>
                      {item.artikel_package
                        ? item.artikel_package
                        : "Unbekannt"}
                    </ArticleCell>

                    {key === 0 && (
                      <ArticleCell
                        width={300}
                        style={{
                          borderLeft: "1px solid rgba(224, 224, 224, 1)",
                          verticalAlign: "text-top",
                        }}
                        rowSpan={articles.length}
                      >
                        <ul>
                          {specifications.length < 1 && (
                            <p>No Specifications available</p>
                          )}
                          {specifications.map((specification, key) => {
                            return <li key={specification.id}>{specification.name}</li>;
                          })}
                        </ul>
                      </ArticleCell>
                    )}
                    {key === 0 && (
                      <ArticleCell
                        width={300}
                        style={{
                          borderLeft: "1px solid rgba(224, 224, 224, 1)",
                          verticalAlign: "text-top",
                        }}
                        rowSpan={articles.length}
                      >
                        <ul>
                          {recommendations.length < 1 && (
                            <p>No Recommendations available</p>
                          )}
                          {recommendations.map((recommendation, key) => {
                            return <li>{recommendation.name}</li>;
                          })}
                        </ul>
                      </ArticleCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default connect(state => ({
  categories: state.categories,
}))(Articles);
