import React from "react";
import { useTranslation } from "react-i18next";
import "./NotFound.scss";
import Catalog from "../../Categories/Catalog";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <div className={"container"}>
      <div className={"error"}>
        <p className={"title"}>{t("error.404.title")}</p>
        <p className={"description"}>{t("error.404.description")}</p>
        <p className={"description"}>{t("error.404.choose")}</p>
      </div>

      <Catalog small />
    </div>
  );
}
