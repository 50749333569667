import { createStore, combineReducers } from "redux";
import categoriesReducer from "./reducers/categoriesReducer";
import productsReducer from "./reducers/productsReducer";

let store = createStore(
  combineReducers({
    categories: categoriesReducer,
    product: productsReducer,
  })
);

export default store;
