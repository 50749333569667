import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Popover, Box } from "@material-ui/core";
import languageToFlag from "../../../data/Languages.js";

export default function LanguageSwitcher({ inMenu = false }) {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div
      style={
        inMenu
          ? { alignSelf: "center", marginRight: 15 }
          : { float: "right", zIndex: 9999 }
      }
    >
      <Tooltip title={"Select Catalog language"}>
        <IconButton
          onClick={e => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <img src={languageToFlag[i18n.language].flag} width={25} />
        </IconButton>
      </Tooltip>

      <Popover
        id={"language-switcher"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: inMenu ? "center" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: inMenu ? "center" : "right",
        }}
        style={{ marginTop: 10 }}
        elevation={25}
      >
        <Box p={2} className={"app-switcher-content"}>
          {Object.keys(languageToFlag).map((language, key) => {
            const lng = languageToFlag[language];
            return (
              <div
                key={key}
                onClick={() => {
                  i18n.changeLanguage(lng.iso6391);
                  setAnchorEl(null);
                }}
                style={{
                  margin: "10px 0",
                  cursor: "pointer",
                  fontWeight: "bold",
                  fontSize: 14,
                  display: "flex",
                }}
              >
                <img
                  src={lng.flag}
                  alt={`Flag for ${lng.iso6391}`}
                  width={25}
                  style={{ marginRight: 20, verticalAlign: "middle" }}
                />
                <span
                  style={{ flexGrow: 1, alignSelf: "center", marginRight: 15 }}
                >
                  {i18n.t("language.translated", { lng: lng.iso6391 })}
                </span>
              </div>
            );
          })}
        </Box>
      </Popover>
    </div>
  );
}
