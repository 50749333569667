import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";  
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./cookie.scss";
import Cookies from "js-cookie";

export default function CookieMessage({opened = false, onClose}) {
  const { t } = useTranslation();

  const [functionalCookie, setFunctionalCookie] = useState(false);
  const [firstRender, setFirstRender] = useState(false);

  if(!firstRender) {
      setFirstRender(true);

      if(Cookies.get("_GGC_COOKIECONSENT") === "functional") {
          setFunctionalCookie(true);
      }
  }

  const handleChange = (event) => {
    setFunctionalCookie(event.target.checked);
  };

  const handleContinue = (all) => {
    let cookieMode = all ? "functional" : functionalCookie ? "functional" : "essential";

    Cookies.set("_GGC_COOKIECONSENT", cookieMode, {
        expires: 365,
    });
    onClose();
  }

  return (
    <Dialog className={"cookie-message"} open={opened} className={"cookie-message"} fullWidth>
        <DialogTitle>Cookie Settings</DialogTitle>
        <DialogContent>
            <p>The German Gold Oil Finder utilizes cookies to improve your browsing experience. Some of them are essential while others are there to improve and measure the website performance. Essential cookies are enabled by default and can't be disabled</p>
        
            <div className={"settings"} style={{marginTop: 20}}>
                <Grid container spacing={3}>
                    <Grid item sm={5} m={6}>
                        <Checkbox
                            color="primary"
                            checked={true}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled
                            />
                            <span>Essential Cookies</span>
                    </Grid>
                    <Grid item sm={5} m={6}>
                        <Checkbox
                            color="primary"
                            checked={functionalCookie}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            onChange={handleChange}
                            />
                            <span>Functional Cookies</span>
                    </Grid>
                </Grid>
            </div>
       
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                handleContinue(false);
            }}>Continue</Button>
            <Button onClick={() => {
                 handleContinue(true);
            }}>Accept all Cookies</Button>
        </DialogActions>
    </Dialog>
  );
}