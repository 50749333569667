import { constants } from "../constants";

const productsReducer = (state = [], action) => {
  switch (action.type) {
    case constants.UPDATE_PRODUCT:
      return [...state, ...action.payload];

    default:
      return state;
  }
};

export default productsReducer;
