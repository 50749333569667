import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "../languages/en.json";
import German from "../languages/de.json";
import Spanish from "../languages/es.json";

const preload = ["en"];
i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_DEBUG === "true",
    initImmediate: false,
    preload,
    fallbackLng: "en",
    lng: "en",
    resources: {
      en: { translation: English },
      de: { translation: German },
      es: { translation: Spanish },

    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
