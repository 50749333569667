import flagEN from "../images/flags/united-kingdom.svg";
import flagDE from "../images/flags/germany.svg";
import flagES from "../images/flags/spain.svg";

const languages = {
  en: {
    iso6391: "en",
    flag: flagEN,
  },
  de: {
    iso6391: "de",
    flag: flagDE,
  },
  es: {
    iso6391: "es",
    flag: flagES,
  },
};

export default languages;
