import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import api from "../../utils/api";
import {
  Grid,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FilledInput,
} from "@material-ui/core";
import algoliasearch from "algoliasearch";
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectSearchBox,
  connectStateResults,
  PoweredBy,
  connectPoweredBy,
  connectHits,
  HitsPerPage,
  Pagination,
  Configure,
  SortBy,
} from "react-instantsearch-dom";
import { useHistory } from "react-router-dom";

export default function Searchbar() {
  let history = useHistory();
  const searchClient = algoliasearch(
    "N96ZGGWUEL",
    "69a9fb327f5845ab907cd09dc0e4eb89"
  );

  const { t } = useTranslation();
  const [refinementSet, setRefinementSet] = useState("");

  let searchWindow = window.location.search;
  let params = new URLSearchParams(searchWindow);

  const MaterialUiSearchBox = ({ currentRefinement, refine }) => {
    useEffect(() => {
      if(params.get("q") !== undefined && params.get("q") !== null) {
        refine(params.get("q"));
      }
    }, [params.get("q")]);

    return (
      <div>
        <FormControl variant="filled" fullWidth={true}>
          <InputLabel>{t("searchbar.searchterm")}</InputLabel>
          <FilledInput
            value={currentRefinement}
            onChange={e => {
              refine(e.target.value);
            }}
            id="SearchBox"
            label={t("searchbar.searchterm")}
            placeholder={t("searchbar.placeholder")}
            variant="filled"
            style={{ width: "100%" }}
            endAdornment={
              <InputAdornment position="end">
                <i className="fas fa-search" style={{ marginRight: 14 }}></i>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    );
  };

  const PoweredByReplacement = ({ url }) => {
    return (
      <div className={"algolia"}>
        <a href={url} target="_BLANK" rel="noreferrer">
          <div className={"icon"}>
            <i className="fab fa-algolia"></i>
          </div>
          <div className={"text"}>by algolia</div>
        </a>
      </div>
    );
  };

  const Results = connectStateResults(({ searchState, searchResults, error }) =>
    error ? (
      <div
        style={{ padding: 15, color: "#ffffff", backgroundColor: "#c0392b" }}
      >
        <span
          style={{
            marginRight: "15px",
            fontSize: 20,
            color: "#ffffff",
          }}
        >
          <i className="far fa-grin-beam-sweat"></i>
        </span>
        Something went wrong. We couldn't search for this product. Are you
        offline? Try again later.
      </div>
    ) : searchResults && searchResults.nbHits !== 0 ? (
      searchState && searchState.query ? (
        <div>
          <ConnectedHits />
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            padding: 15,
            color: "#ffffff",
            marginBottom: 16,
            textTransform: "uppercase",
          }}
        >
          <span style={{ color: "#e7cb75", paddingRight: 9 }}>
            <i className={"fas fa-caret-right"}></i> Search by:
          </span>
          <span>
            name, article number, viscosity, specification or recommended use
          </span>
        </div>
      )
    ) : (
      <div
        style={{
          padding: 15,
          color: "#ffffff",
          backgroundColor: "#c0392b",
          marginBottom: 24,
        }}
      >
        <span
          style={{
            marginRight: "15px",
            fontSize: 20,
            color: "#ffffff",
          }}
        >
          <i className="fas fa-exclamation-triangle"></i>
        </span>
        We couldn't find any product with your search{" "}
        <strong>{searchState.query}</strong>. Change your search term and try
        again.
      </div>
    )
  );

  const HitsReplacement = ({ hits }) => {
    return (
      <div
        className={"searchResults"}
        style={{ marginTop: 24, marginBottom: 44 }}
      >
        <Grid container spacing={2}>
          {hits.map(hit => {
            return (
              <Grid item xs={12} sm={12} md={6} l={6}>
                <Paper
                  className={"hit"}
                  onClick={() => {
                    const searchValue = document.getElementById("SearchBox")
                      .value;
                    history.push(
                      `/products/${hit[0].id}/${hit[0].artikel_name
                        .replace(/\s+/g, "-")
                        .toLowerCase()}?ref=search&q=${searchValue}`
                    );
                  }}
                >
                  <div className={"name"}>
                    <i
                      className={"fas fa-chevron-right"}
                      style={{ marginRight: 7 }}
                    ></i>
                    {hit[0].artikel_name}
                  </div>
                </Paper>{" "}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  };

  const ConnectedSearchBox = connectSearchBox(MaterialUiSearchBox);
  const ConnectedPoweredBy = connectPoweredBy(PoweredByReplacement);
  const ConnectedHits = connectHits(HitsReplacement);

  // Categories
  const [categoryRequestSent, setCategoryRequestSent] = useState(false);
  const [categories, setCategories] = useState([]);

  if (!categoryRequestSent) {
    setCategoryRequestSent(true);

    api(
      `GetCategories`,
      {},
      {},
      {
        "x-api-authorization": "GGAV-APIX-J0HG-UA90-02QQ",
      }
    )
      .then(result => {
        if (
          result.data.errorCode === "101" ||
          result.data.errorCode === "103"
        ) {
          return console.error(result.data.errorMessage);
        }

        setCategories(result.data.result.values);
      })
      .catch(err => {
        console.error("Ladefehler bei kategorien");
      });
  }

  // Car Brands
  const [carBrandRequestSent, setCarBrandRequestSent] = useState(false);
  const [carBrands, setCarBrands] = useState([]);

  if (!carBrandRequestSent) {
    setCarBrandRequestSent(true);

    api(
      `GetCarBrands`,
      {},
      {},
      {
        "x-api-authorization": "GGAV-APIX-J0HG-UA90-02QQ",
      }
    )
      .then(result => {
        if (
          result.data.errorCode === "101" ||
          result.data.errorCode === "103"
        ) {
          return console.error(result.data.errorMessage);
        }

        setCarBrands(result.data.result.values);
      })
      .catch(err => {
        console.error("Ladefehler bei automarken");
        console.error(err);
      });
  }

  // Suche
  const [searchRequest, setSearchRequest] = useState(false);
  const [searchType, setSearchType] = useState("Artikelname");
  const [searchCategory, setSearchCategory] = useState(0);
  const [searchCar, setSearchCar] = useState(0);

  const search = () => {
    if (!searchRequest) {
      setSearchRequest(true);

      api(
        `GetCarBrands`,
        {},
        {},
        {
          "x-api-authorization": "GGAV-APIX-J0HG-UA90-02QQ",
        }
      )
        .then(result => {
          if (
            result.data.errorCode === "101" ||
            result.data.errorCode === "103"
          ) {
            return console.error(result.data.errorMessage);
          }

          setCarBrands(result.data.result.values);
        })
        .catch(err => {
          console.error("Ladefehler bei automarken");
          console.error(err);
        });
    }
  };

  return (
    <div className={"search"}>
      <Grid container spacing={2}>
        {/* <Grid item sm={12} md={4}>
          <FormControl variant="filled" style={{ width: "100%" }}>
            <InputLabel>{t("searchbar.carmark")}</InputLabel>
            <Select
              value={searchCar}
              onChange={e => {
                setSearchCar(e.target.value);
              }}
            >
              <MenuItem value={0}>Keine</MenuItem>
              {carBrands.map((brand, key) => {
                return <MenuItem value={brand.id}>{brand.brand}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={12} md={4}>
          <FormControl variant="filled" style={{ width: "100%" }}>
            <InputLabel>{t("searchbar.category")}</InputLabel>
            <Select
              value={searchCategory}
              onChange={e => {
                setSearchCategory(e.target.value);
              }}
            >
              <MenuItem value={0}>Alle Kategorien</MenuItem>
              {categories.map((category, key) => {
                return <MenuItem value={category.id}>{category.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={12} md={4}>
          <FormControl variant="filled" style={{ width: "100%" }}>
            <InputLabel>{t("searchbar.for.label")}</InputLabel>
            <Select
              value={searchType}
              onChange={e => {
                setSearchType(e.target.value);
              }}
            >
              <MenuItem value={"Artikelname"}>
                {t("searchbar.for.name")}
              </MenuItem>
              <MenuItem value={"Recommended use"}>
                {t("searchbar.for.recommended")}
              </MenuItem>
              <MenuItem value={"Viscosity"}>
                {t("searchbar.for.viscosity")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <InstantSearch searchClient={searchClient} indexName="prod_july">
          <Grid item xs={12} sm={12} md={12}>
            <div className={"searchTitle"}>
              <span className={"italic"}>German Gold <span className={"bold"}>Oil Finder:</span></span>
            </div>
          </Grid>
          <Grid item xs={12} sm={10} md={11}>
            <ConnectedSearchBox />
          </Grid>
          <Grid item xs={12} sm={2} md={1}>
            <ConnectedPoweredBy />
          </Grid>
          <Grid item sm={12} md={12}>
            <Results />
          </Grid>
          <Configure hitsPerPage={50} />
        </InstantSearch>
      </Grid>
    </div>
  );
}
