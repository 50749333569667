import React, { useState, useEffect } from "react";
import "./Items.scss";
import { connect } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../../../utils/api";
import {
  Table,
  TableRow,
  TableCell,
  Checkbox,
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#111111",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "16px",
  },
  body: {
    backgroundColor: "#a09f9f",
    fontSize: "16px",
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function Items({ identifier }) {
  let history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [itemRequestSent, setItemRequestSent] = useState(false);
  const [items, setItems] = useState(null);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("viscosity");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const isSelected2 = name => selected.indexOf(name) !== -1;
  const isSelected = name => selected.map(function(e) { return e.name; }).indexOf(name) !== -1;

  useEffect(() => {
    setItemRequestSent(false);
  }, [identifier]);

  if (!itemRequestSent && identifier && identifier !== null) {
    setItemRequestSent(true);

    var bodyFormData = new FormData();
    bodyFormData.set("c", identifier);

    api(
      `ArticlesByCategory`,
      bodyFormData,
      {},
      {
        "x-api-authorization": "GGAV-APIX-J0HG-UA90-02QQ",
        "Content-Type": "multipart/form-data",
      }
    )
      .then(result => {
        delete result.data.result.values.view;
        setItems(result.data.result.values);
      })
      .catch(err => {
        console.error(err);
      });
  }

  if (items === null) {
    return null;
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = Object.entries(items).map(n => n[1].artikel_name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  let numSelected = selected.length;
  let rowCount = Object.entries(items).length;
  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const handleClick = (event, name) => {
    console.log("Click", name);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <div className={"items"}>
      <Paper style={{ overflow: "hidden" }}>
        <Toolbar className={clsx("bar", numSelected > 0 && "highlighted")}>
          {numSelected > 0 ? (
            <Typography className={"title"}>
              {numSelected} {t("items.selected")}
            </Typography>
          ) : (
            <Typography>{t("items.title")}</Typography>
          )}

          {numSelected > 0 && (
            <div>
              <Tooltip title={t("items.tooltips.watchlist")}>
                <IconButton
                  aria-label="watch"
                  onClick={() => {
                    console.log("Selected", selected);
                    enqueueSnackbar("Product(s) successfully added to your watchlist.");
                    setWatchlist(selected);
                  }}
                >
                  <FavoriteIcon />
                </IconButton>
              </Tooltip>

              <a href={`mailto:info@german-gold.com?subject=${selected}`}>
                <Tooltip title={t("items.tooltips.mail")}>
                  <IconButton aria-label="email">
                    <MailOutlineIcon />
                  </IconButton>
                </Tooltip>
              </a>
            </div>
          )}
        </Toolbar>
        <TableContainer>
          <Table stickyHeader className={"itemTable"}>
            <TableHead>
              <TableRow>
                <StyledTableCell width={10}>
                  <Checkbox
                    onClick={handleSelectAllClick}
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    style={{ color: "#ffffff" }}
                    color="secondary"
                  />
                </StyledTableCell>
                <StyledTableCell sortDirection={orderBy === 1 ? order : false}>
                  <TableSortLabel
                    style={{ color: "#ffffff" }}
                    active={orderBy === 1}
                    direction={orderBy === 1 ? order : "asc"}
                    onClick={createSortHandler(1)}
                  >
                    {t("items.header.name")}
                    {orderBy === 1 ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell
                  width={200}
                  sortDirection={orderBy === 2 ? order : false}
                >
                  <TableSortLabel
                    style={{ color: "#ffffff" }}
                    active={orderBy === 2}
                    direction={orderBy === 2 ? order : "asc"}
                    onClick={createSortHandler(2)}
                  >
                    {t("items.header.viscosity")}
                    {orderBy === 2 ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(Object.entries(items), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row[1].artikel_name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[1].artikel_name}
                      selected={isItemSelected}
                    >
                      <StyledTableCell>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={event =>
                            handleClick(event, {name: row[1].artikel_name, id: row[1].id})
                          }
                          inputProps={{ "aria-labelledby": labelId }} 
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push(
                            `/products/${
                              row[1].id
                            }/${row[1].artikel_name
                              .replace(/\s+/g, "-")
                              .toLowerCase()}?ref=category&l=list`
                          );
                        }}
                      >
                                {row[1].artikel_name}
                      </StyledTableCell>
                      <StyledTableCell>{row[1].artikel_class}</StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 30]}
          component="div"
          count={Object.entries(items).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ backgroundColor: "#6b6b6b" }}
        />
      </Paper>
    </div>
  );
}

function setWatchlist(items) {
  const existingCookie = Cookies.get("_GGC_ITEMS");
  let cookieValue = null;

  if (!Array.isArray(items)) {
    return false;
  }

  if (existingCookie === undefined || existingCookie === null) {
    cookieValue = [];
  } else {
    cookieValue = JSON.parse(existingCookie);
  }

  items.map(item => {
    if (cookieValue.some(citem => citem.name == item.name)) {
      return;
    }

    cookieValue.push(item);
  });

  Cookies.set("_GGC_ITEMS", JSON.stringify(cookieValue), {
    expires: 7,
  });

}

export default connect(state => ({
  categories: state.categories,
}))(Items);
