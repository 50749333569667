import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import "./fab.scss";
import { useHistory } from "react-router";
import { Drawer, Fab, Tooltip, Zoom, Grid, Paper, TextField, FormControlLabel, Switch, Checkbox } from "@material-ui/core";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";

export default function FloatingButton(show = true) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();

  const [showCtab, setShowCtab] = useState(show);
  const [showDrawer, setShowDrawer] = useState(false);

  const [storedItems, setStoredItems] = useState([]);
  const [storeLoaded, setStoreLoaded] = useState(false);

  const [collectionSubmitted, setCollectionSubmitted] = useState(false);

  // AWS
  var AWS = require("aws-sdk");
  AWS.config.region = 'eu-central-1';
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'eu-central-1:207a75d8-007c-428f-b065-03208fe549db',
});

  // Delete Collection Item
  const submitRemoval = (removedItem) => {
    if (
      Cookies.get("_GGC_ITEMS") !== undefined &&
      Cookies.get("_GGC_ITEMS") !== null
    ) {
      let cookieValue = JSON.parse(Cookies.get("_GGC_ITEMS"));
      if (cookieValue !== undefined && cookieValue !== null) {
        const filteredItems = cookieValue.filter((item) => item.id !== removedItem.id);

        Cookies.set("_GGC_ITEMS", JSON.stringify(filteredItems), {
          expires: 7,
        });

        enqueueSnackbar(`${removedItem.name} is now removed from your collection.`)
      }
    }
  };

  // Form
  const [inquiryName, setInquiryName] = useState(null);
  const [inquiryEmail, setInquiryEmail] = useState(null);
  const [inquiryMessage, setInquiryMessage] = useState(null);
  const [inquiryCopy, setInquiryCopy] = useState(false);
  const [inquiryPolicy, setInquiryPolicy] = useState(false);

  const submitInquiry = () => {
    if (!inquiryPolicy) return;
    if (collectionSubmitted) return;

    if(inquiryName == null || inquiryEmail == null || inquiryMessage == null) {
      return enqueueSnackbar("Something went wrong. Please fill in all fields.");
    }

    if(inquiryName.length < 5) {
      return enqueueSnackbar(t("basket.form.error.name"));
    }

    if(inquiryEmail.length < 5) {
      return enqueueSnackbar(t("basket.form.error.email"));
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!emailRegex.test(String(inquiryEmail).toLowerCase())) {
      return enqueueSnackbar(t("basket.form.error.email"));
    }

    if(inquiryMessage.length < 15) {
      return enqueueSnackbar(t("basket.form.error.message"));
    }

    // Email
    // User: eu-central-1_pm0J6MnKa
    // App Client: 105jnke8p31bvmeper9k0lm6t3

    var mailDate = new Date();
    var copies = ['p.boschma@german-gold.com'];
    var collectionString = "";

    if(inquiryCopy) {
      copies.push(inquiryEmail);
    }

    storedItems.map(article => {
      collectionString += `<a href="https://oilfinder.app/products/${
        article.id
      }/${article.name
        .replace(/\s+/g, "-")
        .toLowerCase()}?ref=category&l=inquiry">${article.name}</a><br />`;
    })

    var params = {
      Destination: {
        BccAddresses: copies,
        ToAddresses: [
          'h.harms@german-gold.com',
        ]
      },
      Message: {
        Body: {
          Html: {
           Charset: "UTF-8",
           Data: `<b>Full Name:</b> ${inquiryName} <br /><b>Email Address:</b> ${inquiryEmail} <br /><b>Message:</b> ${inquiryMessage} <br /><br /><b>Inquiry Items:</b><br> ${collectionString}`
          },
          Text: {
           Charset: "UTF-8",
           Data: "GERMAN GOLD Oilfinder Inquiry Form"
          }
         },
         Subject: {
          Charset: 'UTF-8',
          Data: 'Your GERMAN GOLD Oilfinder Inquiry from ' + mailDate.toLocaleDateString("de-DE")
         }
        },
      Source: '"GERMAN GOLD Oilfinder" <p.boschma@german-gold.com>',
      ReplyToAddresses: [
         'info@german-gold.com',
      ],
    };

    var sendEmailPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();
    sendEmailPromise.then(
      function(data) {
        setCollectionSubmitted(true);
      }).catch(
        function(err) {
        enqueueSnackbar(err);
      });
  };

  if(Cookies.get("_GGC_COOKIECONSENT") !== "functional") {
    return null;
  }

  if (!storeLoaded) {
    setStoreLoaded(true);

    if (
      Cookies.get("_GGC_ITEMS") !== undefined &&
      Cookies.get("_GGC_ITEMS") !== null
    ) {
      let cookieValue = JSON.parse(Cookies.get("_GGC_ITEMS"));
      if (cookieValue !== undefined && cookieValue !== null) {
        setStoredItems(cookieValue);
      }
    }
  }

  return (
    <>
    <div className={"ctab"}>
      <Zoom in={true} style={{ transitionDelay: showCtab ? '500ms' : '0ms' }}>
        <Tooltip title={"Show collection"} placement="left-start">
          <Fab variant="extended" color="secondary" onClick={() => {
            if(!showDrawer) {
              setStoreLoaded(false);
            }

            setShowDrawer(!showDrawer);
          }}>
            <i class={"fas fa-heart"} style={{fontSize: 22, paddingTop: 4}}></i> Watchlist
          </Fab> 
        </Tooltip>
      </Zoom>
    </div>
    <div className={"draw"}>
      <Drawer anchor="right" open={showDrawer} onClose={() => {
        setShowDrawer(!showDrawer);
      }}>
      <div className={"pageHeader"}>
        <h1 className={"title"}>{t("basket.title")}</h1>
        <h3 style={{ marginBottom: 0 }}>
          {t("basket.description")}
        </h3>

        <div style={{marginTop: 25, marginBottom: 10}}>
          <Grid container spacing={2} className={"articles"}>
            {storedItems !== null &&
              storedItems !== undefined &&
              storedItems.length > 0 &&
              storedItems.map(article => {
                return (
                  <Grid item sm={12} md={6}>
                    <Paper
                      style={{
                        backgroundColor: "#565656",
                        padding: 8,
                        color: "#FFFFFF",
                        cursor: "pointer",
                      }}
                      className={"item"}
                      onClick={() => {
                        history.push(
                          `/products/${
                            article.id
                          }/${article.name
                            .replace(/\s+/g, "-")
                            .toLowerCase()}?ref=category&l=list`
                        );
                        setShowDrawer(!showDrawer);
                      }}
                    >
                      <div className={"name"}>{article.name}</div>
                      <div className={"delete"} onClick={(e) => {  e.stopPropagation(); submitRemoval(article); e.target.parentNode.parentNode.parentNode.remove(); }}><i className={"fas fa-times"}></i></div>
                    </Paper>
                  </Grid>
                );
              })}
          </Grid>

          {storedItems === null ||
            storedItems === undefined ||
            (storedItems.length < 1 && <p style={{color: "#ffffff"}}>{t("basket.empty")}</p>)}

          <Paper style={{backgroundColor: "#a09f9f", borderRadius: "4px", boxShadow: "10px 11px 5px -11px rgb(0 0 0 / 75%)", padding: 15, marginTop: 25}}>
            {!collectionSubmitted && <>
              <TextField
                label={t("basket.form.name")}
                value={inquiryName}
                onChange={(e) => { setInquiryName(e.target.value); }}
                variant={"filled"}
                placeholder={"Max Mustermann"}
                fullWidth
                />
              <TextField
                label={t("basket.form.email")}
                value={inquiryEmail}
                onChange={(e) => { setInquiryEmail(e.target.value); }}
                variant={"filled"}
                placeholder={"max@mustermail.de"}
                style={{marginTop: 6}}
                fullWidth
                />
              <TextField
                label={t("basket.form.message")}
                value={inquiryMessage}
                onChange={(e) => { setInquiryMessage(e.target.value); }}
                variant={"filled"}
                placeholder={"Hello. Enter the details about your inquiry here. We'll send the articles above with this inquiry. You can add items to your collection by clicking on the heart icon."}
                style={{marginTop: 6}}
                multiline
                rows={5}
                fullWidth
                />

            <FormControlLabel
              control={
                <Checkbox
                  checked={inquiryCopy}
                  onChange={() => { setInquiryCopy(!inquiryCopy)}}
                  color="primary"
                />
              }
              label={t("basket.form.copy")}
            />

            <FormControlLabel
              style={{marginTop: 6}}
              control={
                <Switch
                  checked={inquiryPolicy}
                  onChange={() => { setInquiryPolicy(!inquiryPolicy)}}
                  color="primary"
                />
              }
              label={t("basket.form.privacy")}
            />

              <div
                className={`backButton dark ${inquiryPolicy ? "public" : "disabled"}`}
                style={{ marginBottom: 0, marginTop: 11, float: "right" }}
                disabled
                onClick={submitInquiry}
              >
                <span style={{ color: "#e7cb75", paddingRight: 9 }}>
                  <i className={"fas fa-caret-right"}></i>
                </span>
                <span>{t("basket.form.submit")}</span>
              </div>
              <div style={{ clear: "both" }}></div>
            </>}

            {collectionSubmitted && <>
              <p>{t("basket.form.submitted")}</p>
              <div
                className={"backButton dark"}
                style={{ marginBottom: 0, marginTop: 11, float: "right" }}
                onClick={() => {
                  setCollectionSubmitted(false);
                  setInquiryName(null);
                  setInquiryEmail(null);
                  setInquiryMessage(null);
                  setInquiryCopy(false);
                  setInquiryPolicy(false);
                }}
              >
                <span style={{ color: "#e7cb75", paddingRight: 9 }}>
                  <i className={"fas fa-caret-right"}></i>
                </span>
                <span>{t("basket.form.resubmit")}</span>
              </div>
              <div style={{ clear: "both" }}></div>
            </>}
            
          </Paper>
      </div>

        <div
          className={"backButton"}
          style={{ marginBottom: 0, marginTop: 15, float: "left" }}
          onClick={() => {
            setShowDrawer(!showDrawer);
            setInquiryPolicy(false);
          }}
        >
          <span style={{ color: "#e7cb75", paddingRight: 9 }}>
            <i className={"fas fa-caret-right"}></i>
          </span>
          <span>Close collection list</span>
        </div>
      <div style={{ clear: "both" }}></div>
      </div>
      </Drawer>
    </div>
    </>
  );
}
