import React, { useState } from "react";
import "./footer.scss";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import logo from "../../images/branding/logo-gold.png";
import seal from "../../images/premium-oil-seal.png";
import LazyLoad from "react-lazy-load";
import { BrowserView, MobileView } from "react-device-detect";
import CookieMessage from "../Header/CookieMessage";
import Cookies from "js-cookie";

export default function Footer() {
  const { t } = useTranslation();
  const [cookiePopupOpened, setCookiePopupOpened] = useState(false);
  const [firstRender, setFirstRender] = useState(false);

  if (!firstRender) {
    setFirstRender(true);
    let getCookie = Cookies.get("_GGC_COOKIECONSENT");
    console.error(getCookie);

    if (getCookie === undefined || getCookie === null) {
      setCookiePopupOpened(true);
    }
  }

  const menuItems = [
    {
      name: t("menu.footer.company"),
      to: "en/company/",
    },
    {
      name: t("menu.footer.contact"),
      to: "en/contact/",
    },
    {
      name: t("menu.footer.media"),
      to: "en/media-center/",
    },
  ];

  const legalMenuItems = [
    {
      name: t("menu.footer.privacy"),
      to: "en/privacy-policy/",
    },
    {
      name: t("menu.footer.imprint"),
      to: "imprint/",
    },
  ];

  return (
    <>
      <CookieMessage
        opened={cookiePopupOpened}
        onClose={() => setCookiePopupOpened(false)}
      />
      <LazyLoad>
        <BrowserView>
          <footer>
            <div className={"columns"}>
              <div className={"container"}>
                <div className={"branding"}>
                  <img src={logo} alt={"German Gold Brand"} />
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className={"box"}>
                      <h1>{t("footer.titles.address")}</h1>
                      <div className={"columnContent"}>
                        <p>
                          <span className={"bold"}>GERMAN GOLD </span>
                          <br />
                          Handelsgesellschaft MbH
                        </p>
                        <p>
                         Fahrenheitstr. 11  <br />
                         28359 Bremen
                        </p>
                        <p>
                          <a
                            href="https://www.instagram.com/germangoldoil/"
                            target="_BLANK"
                            rel="noopener noreferrer"
                          >
                            <span className={"bold"}>
                              <i
                                className={"fab fa-instagram"}
                                style={{ marginRight: 3 }}
                              ></i>
                            </span>{" "}
                            @germangoldoil
                          </a>
                          <br />
                          <a
                            href="https://www.facebook.com/GermanGoldOfficial"
                            target="_BLANK"
                            rel="noopener noreferrer"
                          >
                            <span className={"bold"}>
                              <i
                                className={"fab fa-facebook"}
                                style={{ marginRight: 3 }}
                              ></i>
                            </span>{" "}
                            /GermanGoldOfficial
                          </a>
                          <br />
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className={"box"}>
                      <h1>{t("footer.titles.more")}</h1>
                      <div className={"columnContent"}>
                        <div className={"footerMenu"}>
                          <div className={"title"}>
                            {t("footer.titles.info")}
                          </div>
                          <ul>
                            {menuItems.map((obj, key) => {
                              const path = `${obj.to}`;

                              return (
                                <li key={key}>
                                  <a
                                    href={`https://german-gold.de/${path}`}
                                    target="_BLANK"
                                    rel={"noopener noreferrer"}
                                    key={obj.name}
                                  >
                                    {obj.name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className={"footerMenu"}>
                          <div className={"title"} style={{ marginTop: 35 }}>
                            {t("footer.titles.legal")}
                          </div>
                          <ul>
                            {legalMenuItems.map((obj, key) => {
                              const path = `${obj.to}`;

                              return (
                                <li key={key}>
                                  <a
                                    href={`https://german-gold.de/${path}`}
                                    key={obj.name}
                                  >
                                    {obj.name}
                                  </a>
                                </li>
                              );
                            })}
                            <li>
                              <a
                                href={`javascript:void(0)`}
                                key={"cookieSettings"}
                                onClick={() => setCookiePopupOpened(true)}
                              >
                                Cookie Settings
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className={"box sparkles"}>
                      <h1>{t("footer.titles.service")}</h1>
                      <div className={"columnContent"}>
                        <p>{t("footer.service.text")}</p>
                        <p className={"bold"}>{t("footer.service.subtitle")}</p>
                        <p>
                          <span className={"bold"}>
                            <i
                              className={"fas fa-phone"}
                              style={{ paddingRight: 10 }}
                            ></i>
                            {t("footer.information.phone")}:
                          </span>{" "}
                          +49 (0) 421 - 22 300 950 <br />
                          <span className={"bold"}>
                            <i
                              className={"fas fa-envelope"}
                              style={{ paddingRight: 10 }}
                            ></i>
                            {t("footer.information.mail")}:
                          </span>{" "}
                          info@german-gold.com
                        </p>

                        <img
                          src={seal}
                          className={"seal"}
                          alt={"German Gold Quality Seal"}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </footer>
        </BrowserView>
      </LazyLoad>
    </>
  );
}
