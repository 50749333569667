import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { ThemeProvider } from "@material-ui/core";
import App from "./pages/App";
import * as serviceWorker from "./serviceWorker";
import "./utils/i18n.js";
import theme from "./utils/theme.js";
import store from "./store";
import { Provider } from "react-redux";

// Ingest errors
Sentry.init({
  dsn:
    "https://b193bb2c9ea345b6bf784c4735082f9f@o433605.ingest.sentry.io/5389095",
});

// Render application
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
