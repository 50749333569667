import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "./Catalog.scss";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

function Catalog({ categories, small = false }) {
  let history = useHistory();

  return (
    <div className={"items"}>
      <div className={"container"}>
        
            <Grid container spacing={2}>
              {categories.map((brand, key) => {
                const gridStyle = {
                  backgroundImage: "url(" + brand.path + ")",
                };

                return (
                  <Grid item xs={12} sm={6} md={4} key={brand.short}>
                    <Paper
                      className={clsx("outer", small && "small")}
                      onClick={() => {
                        history.push(
                          `/products/category/${brand.short}/${brand.name
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`
                        );
                      }}
                    >
                      <div className={"name"}>{brand.name}</div>
                      <div className={"item"} style={gridStyle}></div>
                      <div className={"clear"}></div>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>

        
      </div>
    </div>
  );
}

export default connect(state => ({
  categories: state.categories,
}))(Catalog);
