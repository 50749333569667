import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import LazyLoad from 'react-lazy-load';

function PersonalStore({ categories, small = false }) {
  const { t } = useTranslation();

  const [storedItems, setStoredItems] = useState([]);
  const [storeLoaded, setStoreLoaded] = useState(false);

  if(Cookies.get("_GGC_COOKIECONSENT") !== "functional") {
    return null;
  }

  if (!storeLoaded) {
    setStoreLoaded(true);

    if (
      Cookies.get("_GGC_ITEMS") !== undefined &&
      Cookies.get("_GGC_ITEMS") !== null
    ) {
      let cookieValue = JSON.parse(Cookies.get("_GGC_ITEMS"));
      if (cookieValue !== undefined && cookieValue !== null) {
        setStoredItems(cookieValue);
      }
    }
  }

  return (
    <div className={"items"}>
      <div className={"container"}>
        <LazyLoad height={200} offset={200} once>
            <Paper style={{ backgroundColor: "#6b6b6b", padding: 15 }}>
              <div className="blockHeader">
                <h3 className="title">{t("basket.title")}</h3>
                <p>{t("basket.description")}</p>
              </div>

              <Grid container spacing={2} className={"articles"}>
                {storedItems !== null &&
                  storedItems !== undefined &&
                  storedItems.length > 0 &&
                  storedItems.map(article => {
                    return (
                      <Grid item sm={12} md={4}>
                        <Paper
                          style={{
                            backgroundColor: "#565656",
                            padding: 8,
                            color: "#FFFFFF",
                          }}
                        >
                          <div className={"name"}>{article}</div>
                        </Paper>
                      </Grid>
                    );
                  })}
              </Grid>

              {storedItems === null ||
                storedItems === undefined ||
                (storedItems.length < 1 && <p style={{color: "#ffffff"}}>{t("basket.empty")}</p>)}
            </Paper>
        </LazyLoad>
      </div>
    </div>
  );
}

export default connect(state => ({
  categories: state.categories,
}))(PersonalStore);
